<a
	mat-tab-link
	class="menu__link"
	[routerLink]="'/' + navigation.link"
	routerLinkActive
	#linkStatus="routerLinkActive"
	[active]="linkStatus.isActive || hasActiveChildren"
>
	{{ navigation.title }}

	@if (navigation.children.length > 0) {
		<mat-icon>keyboard_arrow_down</mat-icon>
	}
</a>
